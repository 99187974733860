import React from 'react';
import {TeamSection,HeadingLayout,TeamHeading,TeamLayout,
    TeamDetailsLayout,Name,Designation,Divider,SocialIconLayout,
    FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon
} from './team.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const TeamPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutus1PageJson{
            Team{
                TeamHeading
                Details{
                    TeamImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Name
                    Designation
                }

            }
        }
    }
  `);

    return (
    <TeamSection>
        <Container>
            <Row>
                <Col md={12}>
                <HeadingLayout>
                    <TeamHeading>
                    {JSONData.aboutus1PageJson.Team.TeamHeading}
                    </TeamHeading>
                </HeadingLayout>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <TeamLayout>
                        <GatsImg 
                            fluid={JSONData.aboutus1PageJson.Team.Details[0].TeamImage.childImageSharp.fluid} 
                            alt=""
                        />
                        <TeamDetailsLayout>
                            <Name>
                            {JSONData.aboutus1PageJson.Team.Details[0].Name} 
                            </Name>
                            <Designation>
                            {JSONData.aboutus1PageJson.Team.Details[0].Designation} 
                            </Designation>
                            <Divider/>
                            <SocialIconLayout>
                                <FbIcon/>
                                <TwitterIcon/>
                                <InstaIcon/>
                                <LinkedinIcon/>
                            </SocialIconLayout>
                        </TeamDetailsLayout>
                    </TeamLayout>
                </Col>
                <Col md={4}>
                    <TeamLayout>
                        <GatsImg 
                            fluid={JSONData.aboutus1PageJson.Team.Details[1].TeamImage.childImageSharp.fluid} 
                            alt=""
                        />
                        <TeamDetailsLayout>
                            <Name>
                            {JSONData.aboutus1PageJson.Team.Details[1].Name} 
                            </Name>
                            <Designation>
                            {JSONData.aboutus1PageJson.Team.Details[1].Designation} 
                            </Designation>
                            <Divider/>
                            <SocialIconLayout>
                                <FbIcon/>
                                <TwitterIcon/>
                                <InstaIcon/>
                                <LinkedinIcon/>
                            </SocialIconLayout>
                        </TeamDetailsLayout>
                    </TeamLayout>
                </Col>
                <Col md={4}>
                    <TeamLayout>
                        <GatsImg 
                            fluid={JSONData.aboutus1PageJson.Team.Details[2].TeamImage.childImageSharp.fluid} 
                            alt=""
                        />
                        <TeamDetailsLayout>
                            <Name>
                            {JSONData.aboutus1PageJson.Team.Details[2].Name} 
                            </Name>
                            <Designation>
                            {JSONData.aboutus1PageJson.Team.Details[2].Designation} 
                            </Designation>
                            <Divider/>
                            <SocialIconLayout>
                                <FbIcon/>
                                <TwitterIcon/>
                                <InstaIcon/>
                                <LinkedinIcon/>
                            </SocialIconLayout>
                        </TeamDetailsLayout>
                    </TeamLayout>
                </Col>
            </Row>
        </Container>
    </TeamSection>
      
    );
}
export default TeamPage;