import styled from 'styled-components';
import {device} from '../../../components/device';
import {Commonpara,Commonh4,SectionHeading} from '../../../components/common.style';

export const WhyChooseUsSection = styled.section`
    background:#2560d4;
`;

export const WhyChooseUsWrapper = styled.div`
    display:flex;
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column;
    }

    @media ${device.minlaptopL} {
        max-width:1140px;
        margin:auto;
    }
`;

export const WhyChooseUsLeftLayout = styled.div`
    width:50%;
    padding-right:60px;
    padding-left:15px;

    @media ${device.laptopL} {
        margin-left: calc(50% - 570px);
    }

    @media ${device.laptopM} {
        margin-left: calc(50% - 480px);
    }

    @media ${device.laptop} {
        width:100%;
        margin:auto;
        padding:100px 15px 40px;
        max-width: 720px;
    }

    @media ${device.tablet} {
        width:100%;
        max-width: 540px;
        margin:auto;
        padding:80px 15px 40px;
    }
`;

export const WhyChooseUsHeading = styled(SectionHeading)`
    color:#fff;
    text-align:left;
    margin-bottom:40px;

    @media ${device.laptop} {
        margin-bottom:75px;
        text-align:center;
    }
    @media ${device.tablet} {
        text-align:center;
        margin-bottom:60px;
    }
`;

export const WhyChooseUsSingle = styled.div`
    display:flex;
    margin-bottom:30px;

    :last-child{
        margin-bottom:0px;
    }
`;

export const NumberLayout = styled.div`
    position: relative;

    ::before {
        content: "";
        border-left: ${props => props.BeforeReq?"1px solid #fff":"none"};
        position: absolute;
        height:100%;
        left:50%;
        margin-top:30px;
    }
`;

export const NumberText = styled(Commonh4)`
    background:#fff;
    padding:15px;
    font-size:26px;
    line-height:26px;
    border-radius:100%;
    color:#2560d4;
`;

export const TextLayout = styled.div`
    margin-left:20px;
    margin-top:10px;
`;

export const SubHeading = styled(Commonh4)`
    color:#fff;
    text-align:left;
    font-size:22px;
    line-height:26px;
    margin-bottom:15px;
`;

export const Content = styled(Commonpara)`
    color:#fff;
    text-align:left;
    opacity:0.9;
    margin-bottom:0px;
`;

export const WhyChooseUsRightLayout = styled.div`
    width:50%;
    flex-shrink:0;

    @media ${device.minlaptopL} {
        padding-right:15px;
    }
    
    @media ${device.laptop} {
        width:100%;
    }

    .RightImage{
        @media ${device.laptopM} {
            min-height:600px;
        }
        @media ${device.laptop} {
            height:350px;
        }
        @media ${device.tablet} {
            height:300px;
        }
    }
`;