import React from "react";
import Banner from "../containers/CommonBanner";
import Headermenu from "../containers/HeaderMenuBS";
import Footer from "../containers/Footer";
import About from "../containers/AboutUs1Page/About";
import WhyChooseUs from "../containers/AboutUs1Page/WhyChooseUs";
import WhatWeDo from "../containers/AboutUs1Page/WhatWeDo";
import Team from "../containers/AboutUs1Page/Team";
import GlobalStyle from "../components/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import BackgroundImg from '../assets/aboutus-1-images/banner.jpg';
import Fonts from "../components/fonts";

const Aboutus1 = () => (
   <div>
       <Fonts/>
       <GlobalStyle />
       <main>
            <SEO 
                title="About Us 1 | Pool Website Template | GatsbyJS Templates | Garnet"
                description="Clean and professional React GatsbyJS Pool Website template from Garnet"
            />
            <Headermenu />
            <Banner 
                BackgroundImg={BackgroundImg}
                pageTitle="About Us"
                breadCrumbsFirst="Home"
                breadCrumbsSecond="About Us"
                breadCrumbsFirstLink="/"
                breadCrumbsSecondLink="/aboutus-1"
            />
            <About />
            <WhyChooseUs />
            <WhatWeDo />
            <Team />
        </main>
        <Footer />
   </div>
)

export default Aboutus1;
