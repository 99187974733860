import React from 'react';
import {WhyChooseUsSection,WhyChooseUsWrapper,WhyChooseUsLeftLayout,WhyChooseUsHeading,WhyChooseUsSingle,
    NumberLayout,NumberText,TextLayout,SubHeading,Content,WhyChooseUsRightLayout
} from './whychooseus.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WhyChooseUsPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutus1PageJson{
            WhyChooseUs{
                WhyChooseUsHeading
                Details{
                        NumberLayout
                        SubHeading
                        Content
                    }
                RightImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);

    return (
            <WhyChooseUsSection>
                <WhyChooseUsWrapper>
                    <WhyChooseUsLeftLayout>
                        <WhyChooseUsHeading>
                        {JSONData.aboutus1PageJson.WhyChooseUs.WhyChooseUsHeading}
                        </WhyChooseUsHeading>
                        <WhyChooseUsSingle>
                            <NumberLayout BeforeReq>
                                <NumberText>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[0].NumberLayout}
                                </NumberText>
                            </NumberLayout>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[0].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[0].Content}
                                </Content>
                            </TextLayout>
                        </WhyChooseUsSingle>
                        <WhyChooseUsSingle>
                            <NumberLayout BeforeReq>
                                <NumberText>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[1].NumberLayout}
                                </NumberText>
                            </NumberLayout>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[1].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[1].Content}
                                </Content>
                            </TextLayout>
                        </WhyChooseUsSingle>
                        <WhyChooseUsSingle>
                            <NumberLayout>
                                <NumberText>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[2].NumberLayout}
                                </NumberText>
                            </NumberLayout>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[2].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhyChooseUs.Details[2].Content}
                                </Content>
                            </TextLayout>
                        </WhyChooseUsSingle>
                    </WhyChooseUsLeftLayout>
                    <WhyChooseUsRightLayout>
                        <GatsImg 
                            fluid={JSONData.aboutus1PageJson.WhyChooseUs.RightImage.childImageSharp.fluid} 
                            alt=""
                            className="RightImage"
                        />
                    </WhyChooseUsRightLayout>
                </WhyChooseUsWrapper>
            </WhyChooseUsSection>
    );
}
export default WhyChooseUsPage;