import styled from 'styled-components';
import {Commonpara,Commonh4,SectionHeading} from '../../../components/common.style';
import {device} from '../../../components/device';

export const WhatWeDoSection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;
    @media ${device.tablet} {
        margin-bottom:60px;
    }

`;

export const WhatWeDoHeading = styled(SectionHeading)`

`;

export const WhatWeDoSingleLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:30px;
`;

export const ImageHolder = styled.div`
    width:130px;
    flex-shrink:0;
    @media ${device.mobileXL} {
        width:110px;
    }
    @media ${device.mobileL} {
        width:90px;
    }
    @media ${device.mobileM} {
        width:70px;
    }
`;

export const WhatWeDoImage = styled.img`

`;

export const TextLayout = styled.div`
    margin-left:20px;
`;

export const SubHeading = styled(Commonh4)`
    text-align:left;
    font-size:20px;
    line-height:20px;
    margin-bottom:10px;
`;

export const Content = styled(Commonpara)`
    margin-bottom:0px;
`;
