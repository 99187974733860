import React from 'react';
import {WhatWeDoSection,HeadingLayout,WhatWeDoHeading,WhatWeDoSingleLayout,
    ImageHolder,WhatWeDoImage,TextLayout,SubHeading,Content
} from './whatwedo.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const WhatWeDoPage  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutus1PageJson{
            WhatWeDo{
                WhatWeDoHeading
                Details{
                    WhatWeDoImage
                    SubHeading
                    Content
                }
            }
        }
    }
  `);

    return (
        <WhatWeDoSection>
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <WhatWeDoHeading>
                            {JSONData.aboutus1PageJson.WhatWeDo.WhatWeDoHeading}
                            </WhatWeDoHeading>
                        </HeadingLayout>
                    </Col>
               </Row>
               <Row>
                   <Col lg={6}>
                        <WhatWeDoSingleLayout>
                            <ImageHolder>
                            <WhatWeDoImage 
                                src={JSONData.aboutus1PageJson.WhatWeDo.Details[0].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[0].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[0].Content}
                                </Content>
                            </TextLayout>
                        </WhatWeDoSingleLayout>
                   </Col>
                   <Col lg={6}>
                        <WhatWeDoSingleLayout>
                            <ImageHolder>
                            <WhatWeDoImage 
                                src={JSONData.aboutus1PageJson.WhatWeDo.Details[1].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[1].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[1].Content}
                                </Content>
                            </TextLayout>
                        </WhatWeDoSingleLayout>
                   </Col>
               </Row>
               <Row>
                    <Col lg={6}>
                        <WhatWeDoSingleLayout>
                            <ImageHolder>
                            <WhatWeDoImage 
                                src={JSONData.aboutus1PageJson.WhatWeDo.Details[2].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[2].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[2].Content}
                                </Content>
                            </TextLayout>
                        </WhatWeDoSingleLayout>
                   </Col>
                   <Col lg={6}>
                        <WhatWeDoSingleLayout>
                            <ImageHolder>
                            <WhatWeDoImage 
                                src={JSONData.aboutus1PageJson.WhatWeDo.Details[3].WhatWeDoImage} 
                                alt=""
                            />
                            </ImageHolder>
                            <TextLayout>
                                <SubHeading>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[3].SubHeading}
                                </SubHeading>
                                <Content>
                                {JSONData.aboutus1PageJson.WhatWeDo.Details[3].Content}
                                </Content>
                            </TextLayout>
                        </WhatWeDoSingleLayout>
                   </Col>
               </Row>
            </Container>
        </WhatWeDoSection>
    );
}
export default WhatWeDoPage;